import React, { useEffect, useState } from 'react'
import { WagerWireIcon } from '../svg'

type SparketsBannerProps = {
  /** Group color, to set the `iframe` primary theme color */
  groupColor?: string
  /** Optional styles */
  style?: React.CSSProperties
}

const xs = 480
const sm = 640

export const SparketsBanner = ({ groupColor, style }: SparketsBannerProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <section style={style}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          color: 'black',
          borderRadius: '4px 0 0 4px',
          boxShadow: '0px 6px 6px 0px #00000040',
          backgroundColor: 'white',
          minHeight: 'max-content',
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              display: 'inline-flex',
              flexDirection: windowWidth >= sm ? 'row' : 'column',
              alignItems: 'center',
              justifyContent: windowWidth >= sm ? 'space-between' : 'initial',
              width: '100%',
              padding: '.2rem .6rem',
            }}
          >
            <div
              style={{
                alignSelf: 'flex-start',
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                }}
              >
                <span role='img' aria-label='money bag'>
                  💰
                </span>{' '}
                <span style={{ color: '#339D0E' }}>LIVE!</span> BUY AND SELL
                BETS
              </div>
              <div
                style={{
                  fontSize: '0.75rem',
                  fontWeight: 'normal',
                }}
              >
                Buy bets after tip off or sell them up to the buzzer.
              </div>
              <div
                style={{
                  fontSize: '0.75rem',
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                  color: '#339D0E',
                }}
              >
                Get them before they vanish{' '}
                <span role='img' aria-label='magic star'>
                  ✨
                </span>{' '}
                Magic Bets
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                fontSize: '0.75rem',
                alignSelf: windowWidth <= xs ? 'flex-end' : 'initial',
                paddingRight: windowWidth >= sm ? '0.25rem' : 'none',
                flexDirection: windowWidth >= sm ? 'column' : 'row',
              }}
            >
              <div
                style={{
                  marginLeft: 'auto',
                  display: windowWidth >= sm ? 'flex' : 'none',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: windowWidth >= sm ? '0.25rem' : 'none',
                  width: '9rem',
                  backgroundColor: '#81FF54',
                  color: 'black',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  borderRadius: '0.375rem',
                  alignSelf: 'flex-end',
                  height: '2.5rem',
                  fontSize: '.875rem',
                }}
              >
                BUY LIVE BETS
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  ...(windowWidth >= sm && { flexDirection: 'row' }),
                }}
              >
                POWERED BY
                <span style={{ margin: '0.25rem' }}>
                  <WagerWireIcon size={12} />
                </span>
                WAGERWIRE
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: groupColor,
              width: '10.5%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'auto',
              marginLeft: 'auto',
              fontWeight: '600',
              fontSize: windowWidth >= sm ? '1.1rem' : 'initial',
            }}
          >
            <span className='text-black'>&#62;</span>
          </div>
        </div>
      </div>
    </section>
  )
}
