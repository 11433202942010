import * as React from 'react'
import { WagerWireContext } from './WagerWireContext'
import { WagerWireIcon } from '../svg'
import { contextError } from './utils'

type SparketActivity =
  | 'baseball'
  | 'basketball'
  | 'combat sports'
  | 'esports'
  | 'football'
  | 'golf'
  | 'hockey'
  | 'other'
  | 'racing'
  | 'reality tv'
  | 'soccer'
  | 'tennis'

export type SparketBet = {
  activity: SparketActivity
  bet_amount: number
  bet_created: string
  bet_id: string
  contestant_id: string
  contestant_name: string
  estimated_payout: number
  event_id: string
  event_name: string
  event_starts: string
  group_color_hex: string
  group_id: string
  group_name: string
  odds: number
  pool_close: string
  pool_id: string
  pool_name: string
  user_id: string
}

type SellBetButtonProps = {
  /** Sparket `bet` object */
  bet: SparketBet
  /** Color */
  color?: string
  /** Hover color */
  hoverColor?: string
  /** Group id */
  groupId?: string
  /** Group color, to set the `iframe` primary theme color */
  groupColor?: string
  /** Optional styles */
  style?: React.CSSProperties
}

/** WagerWire `Sell Bet` button component */
export const SellBetButton = ({
  bet,
  color = '#02fddd',
  hoverColor = '#6bb3a1',
  groupId,
  groupColor,
  style,
}: SellBetButtonProps) => {
  const context = React.useContext(WagerWireContext)

  if (!context) throw new Error(contextError('SellBetButton'))

  const { embedOrigin, iframeSrc, setIframeVisible } = context
  const [hovered, setHovered] = React.useState(false)

  // Send `postMessage` events to child `<iframe>` to pass group information
  const sendGroupInformation = () => {
    const iframe = document.getElementById(
      'wagerwire-iframe',
    ) as HTMLIFrameElement
    if (!iframe || iframe?.tagName !== 'IFRAME') return

    iframe?.contentWindow?.postMessage(
      { key: 'groupId', value: groupId },
      embedOrigin,
    )

    iframe?.contentWindow?.postMessage(
      { key: 'hexColor', value: groupColor },
      embedOrigin,
    )
  }

  // Send `postMessage` event to child `<iframe>` to navigate to the `create` page
  const navigate = (path: string) => {
    const iframe = document.getElementById(
      'wagerwire-iframe',
    ) as HTMLIFrameElement
    if (!iframe || iframe?.tagName !== 'IFRAME') return

    iframe?.contentWindow?.postMessage(
      { key: 'navigate', value: path },
      embedOrigin,
    )
  }

  const handleClick = async () => {
    if (!groupId || !groupColor)
      console.error(
        '`groupId` and `groupColor` are required to set group information',
      )

    // Send group information to child `<iframe>`
    if (groupId && groupColor) sendGroupInformation()

    // Construct the child `<iframe>`'s `src`, passing the `bet` object as search params
    const src = new URL(iframeSrc)
    const sparketBet = {
      ...bet,
      activity: bet.activity ?? '',
      bet_amount: bet.bet_amount.toString(),
      estimated_payout: bet.estimated_payout.toString(),
      odds: bet.odds.toString(),
    }
    const params = new URLSearchParams(sparketBet).toString()
    const url = new Promise<string>((resolve, reject) => {
      if (!src) reject('src is required')
      if (!bet) reject('bet is required')
      resolve(`${src.origin}/listings/create?${params}`)
    })

    // Set the child `<iframe>`'s `src` and make it visible
    navigate(await url)
    setIframeVisible(true)
  }

  return (
    <button
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        fontFamily: 'inherit',
        fontSize: 14,
        fontWeight: 700,
        color: '#000000',
        border: 'none',
        borderRadius: 6,
        padding: '0.5rem 0.75rem',
        backgroundColor: hovered ? hoverColor : color,
        cursor: 'pointer',
        transition: 'background-color 0.2s ease-in-out',
        ...style,
      }}
    >
      <WagerWireIcon size={24} />
      <span style={{ marginLeft: '6px' }}>Sell Bet</span>
    </button>
  )
}
