import * as React from 'react'
import { WagerWireIcon } from '../svg'

type LiveMarketsProps = {
  /** Optional React children */
  children?: React.ReactNode
  /** Optional styles */
  style?: React.CSSProperties
}

/** WagerWire animated `Live Markets` indicator */
export const LiveMarkets = ({
  children = 'Live Markets',
  style,
}: LiveMarketsProps) => {
  // Define CSS animation
  const keyframes = `@keyframes pulse { 50% { opacity: 0.5; } }`

  return (
    <>
      {/* Add inline style for CSS animation */}
      <style children={keyframes} />

      {/* Component */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          ...style,
        }}
      >
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#4ade80',
            width: 16,
            height: 16,
            borderRadius: 8,
            // Use `pulse` CSS animation
            animation: 'pulse 2s cubic-bezier(.4, 0, .6, 1) infinite',
          }}
        >
          <WagerWireIcon size={12} style={{ opacity: 0.4 }} />
        </span>
        <span>{children}</span>
      </div>
    </>
  )
}
