import React, { useEffect, useContext, useCallback } from 'react'
import { use100vh } from 'react-div-100vh'
import { WagerWireContext } from './WagerWireContext'
import { contextError } from './utils'

interface WagerWireIframeProps extends React.HTMLAttributes<HTMLIFrameElement> {
  /** `allow` attribute for `<iframe>` */
  allow?: string
  /** Optional styles */
  style?: React.CSSProperties
}

/** WagerWire iframe marketplace component */
export const WagerWireIframe = ({
  allow = 'clipboard-write',
  style,
}: WagerWireIframeProps) => {
  const context = useContext(WagerWireContext)

  if (!context) throw new Error(contextError('WagerWireIframe'))

  const height = use100vh()
  const { embedOrigin, iframeSrc, iframeVisible, userId } = context

  // Send `postMessage` event to child `<iframe>` to identify user
  const identifyUser = () => {
    const iframe = document.getElementById(
      'wagerwire-iframe',
    ) as HTMLIFrameElement

    if (!iframe || iframe?.tagName !== 'IFRAME') return

    iframe?.contentWindow?.postMessage(
      { key: 'identifyUser', value: userId },
      embedOrigin,
    )
  }

  // Prevent scrolling on body element when `<iframe>` is visible
  useEffect(() => {
    const className = 'wagerwire-iframe-visible'
    if (iframeVisible) document.body.classList.add(className)
    if (!iframeVisible) document.body.classList.remove(className)
  }, [iframeVisible])

  // Memoize identifyUser
  const memoizedIdentifyUser = useCallback(identifyUser, [identifyUser])

  // Re-identify user if `userId` changes
  useEffect(() => {
    memoizedIdentifyUser()
  }, [userId, memoizedIdentifyUser])

  return (
    <iframe
      id='wagerwire-iframe'
      title='wagerwire-iframe'
      onLoad={identifyUser} // Identify user on `<iframe>` load
      src={iframeSrc}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: height ? height : '100vh',
        border: 'none',
        opacity: iframeVisible ? 1 : 0,
        pointerEvents: iframeVisible ? 'auto' : 'none',
        transition: 'opacity 1s ease-in-out',
        ...style,
      }}
      allow={allow}
    />
  )
}
