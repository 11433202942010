import * as React from 'react'
import { WagerWireContext } from './WagerWireContext'
import { WagerWireIcon } from '../svg'
import { contextError } from './utils'

type ListedBetButtonProps = {
  /** React children */
  children: React.ReactNode
  /** Color */
  color?: string
  /** Hover color */
  hoverColor?: string
  /** Group id */
  groupId?: string
  /** Group color, to set the `iframe` primary theme color */
  groupColor?: string
  /** Order id */
  orderId: string
  /** Optional styles */
  style?: React.CSSProperties
}

/** WagerWire `Listed Bet` button component */
export const ListedBetButton = ({
  children,
  color = '#8b939c',
  hoverColor = '#2e343b',
  groupId,
  groupColor,
  orderId,
  style,
}: ListedBetButtonProps) => {
  const context = React.useContext(WagerWireContext)

  if (!context) throw new Error(contextError('ListedBetButton'))

  const { embedOrigin, iframeSrc, setIframeVisible } = context
  const [hovered, setHovered] = React.useState(false)

  // Send `postMessage` events to child `<iframe>` to pass group information
  const sendGroupInformation = () => {
    const iframe = document.getElementById(
      'wagerwire-iframe',
    ) as HTMLIFrameElement

    if (!iframe || iframe?.tagName !== 'IFRAME' || !groupId) return

    iframe?.contentWindow?.postMessage(
      { key: 'groupId', value: groupId },
      embedOrigin,
    )

    iframe?.contentWindow?.postMessage(
      { key: 'hexColor', value: groupColor },
      embedOrigin,
    )
  }

  // Send `postMessage` event to child `<iframe>` to navigate to the listed bet page
  const navigate = (path: string) => {
    const iframe = document.getElementById(
      'wagerwire-iframe',
    ) as HTMLIFrameElement
    if (!iframe || iframe?.tagName !== 'IFRAME') return

    iframe?.contentWindow?.postMessage(
      { key: 'navigate', value: path },
      embedOrigin,
    )
  }

  const handleClick = async () => {
    if (!groupId || !groupColor)
      console.error(
        '`groupId` and `groupColor` are required to set group information',
      )

    // Send group information to child `<iframe>`
    if (groupId && groupColor) sendGroupInformation()

    // Construct the child `<iframe>`'s `src`, passing the `bet` object as search params
    const src = new URL(iframeSrc)
    const url = new Promise<string>((resolve, reject) => {
      if (!src) reject('src is required')
      if (!orderId) reject('orderId is required')
      resolve(`${src.origin}/listings/${orderId}`)
    })

    // Set the child `<iframe>`'s `src` and make it visible
    navigate(await url)
    setIframeVisible(true)
  }

  return (
    <button
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        fontFamily: 'inherit',
        fontSize: 14,
        fontWeight: 700,
        color: '#ffffff',
        border: 'none',
        borderRadius: 6,
        padding: '0.5rem 0.75rem',
        backgroundColor: hovered ? hoverColor : color,
        cursor: 'pointer',
        transition: 'background-color 0.2s ease-in-out',
        ...style,
      }}
    >
      <WagerWireIcon size={24} />
      <span style={{ marginLeft: '6px' }}>Listed {children}</span>
    </button>
  )
}
