import * as React from 'react'

type WagerWireIconProps = {
  /** Size of the SVG icon */
  size?: number
  /** Optional styles */
  style?: React.CSSProperties
}

/** WagerWire logo, as SVG icon */
export const WagerWireIcon = ({ size = 16, style }: WagerWireIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    style={{ ...style }}
  >
    <circle cx='8' cy='8' r='8' fill='#2E2E2E' />
    <path
      d='M9.71865 9.74789L11.0041 7.15525L11.6066 8.32702C11.6666 8.4437 11.7853 8.51618 11.9167 8.51618H14.7709V7.92382H12.0657L11.263 6.36238L10.734 6.36617L9.45853 8.9387L8.22984 6.36219L7.69366 6.36497L6.50799 8.91879L5.29741 6.36657L4.76502 6.36039L3.97849 7.92362H1.18429V8.51598H4.12906C4.26171 8.51598 4.38102 8.44251 4.44057 8.32404L5.02216 7.16819L6.24348 9.74291L6.78006 9.74072L7.96633 7.18551L9.18626 9.74351L9.71925 9.74749L9.71865 9.74789Z'
      fill='url(#paint0_linear_17_246)'
    />
    <path
      d='M8.00854 14.8571C9.83396 14.8571 11.5508 14.1465 12.8439 12.8552C13.4748 12.2251 13.9706 11.4909 14.3167 10.673C14.6755 9.82615 14.8573 8.92676 14.8573 7.99989C14.8573 7.07302 14.6761 6.17622 14.3183 5.33079C13.973 4.51423 13.4786 3.7809 12.8488 3.15131C12.2193 2.52172 11.4859 2.02732 10.6693 1.68186C9.8238 1.32426 8.92573 1.14286 8.00017 1.14286C6.16938 1.14286 4.44812 1.85608 3.1539 3.15131C1.86067 4.44554 1.14643 6.16726 1.14305 7.99929C1.14125 8.90525 1.3217 9.7915 1.67922 10.6331C2.02479 11.4467 2.51893 12.1806 3.14792 12.8142C3.7795 13.4508 4.51305 13.9518 5.32787 14.3032C6.17674 14.6694 7.07581 14.8557 7.99977 14.8569C8.00276 14.8569 8.00555 14.8569 8.00854 14.8569V14.8571ZM8.00017 1.73542C9.67343 1.73542 11.2467 2.38712 12.4298 3.57024C13.6131 4.75357 14.2646 6.32675 14.2646 8.00009C14.2646 9.67343 13.6113 11.2518 12.4252 12.4361C11.2441 13.6155 9.67562 14.2648 8.00854 14.2648C8.00575 14.2648 8.00336 14.2648 8.00057 14.2648C7.15747 14.2638 6.33728 14.0937 5.56269 13.7596C4.81759 13.4382 4.14677 12.9799 3.56857 12.3973C2.99296 11.8173 2.54084 11.1459 2.22475 10.4018C1.89871 9.6342 1.73399 8.82641 1.73559 8.00069C1.73877 6.32635 2.39146 4.75277 3.57316 3.57024C4.75545 2.38712 6.32752 1.73542 8.00017 1.73542Z'
      fill='url(#paint1_linear_17_246)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_17_246'
        x1='7.97748'
        y1='9.74789'
        x2='7.97748'
        y2='6.36059'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#00FFD2' />
        <stop offset='0.63' stopColor='#5FF244' />
        <stop offset='0.8' stopColor='#8DEE04' />
        <stop offset='0.91' stopColor='#B6EA00' />
        <stop offset='1' stopColor='#FFE200' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_17_246'
        x1='8.00017'
        y1='1.14286'
        x2='8.00017'
        y2='14.8571'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#00FFD2' />
        <stop offset='0.75' stopColor='#5FF244' />
        <stop offset='0.83' stopColor='#BDEA00' />
        <stop offset='1' stopColor='#FFE200' />
      </linearGradient>
    </defs>
  </svg>
)
