import * as React from 'react'
import { WagerWireContext } from './WagerWireContext'
import { contextError } from './utils'

type ViewMarketplaceButtonProps = {
  /** React children */
  children: React.ReactNode
  /** Group id */
  groupId?: string
  /** Group color, to set the `iframe` primary theme color */
  groupColor?: string
  /** Optional styles */
  style?: React.CSSProperties
}

/** WagerWire `View Marketplace` button component */
export const ViewMarketplaceButton = ({
  children,
  groupId,
  groupColor,
  style,
}: ViewMarketplaceButtonProps) => {
  const context = React.useContext(WagerWireContext)

  if (!context) throw new Error(contextError('ViewMarketplaceButton'))

  const { embedOrigin, iframeSrc, setIframeVisible } = context

  // Send `postMessage` events to child `<iframe>` to pass group information
  const sendGroupInformation = () => {
    const iframe = document.getElementById(
      'wagerwire-iframe',
    ) as HTMLIFrameElement

    if (!iframe || iframe?.tagName !== 'IFRAME' || !groupColor) return

    iframe?.contentWindow?.postMessage(
      { key: 'groupId', value: groupId },
      embedOrigin,
    )

    iframe?.contentWindow?.postMessage(
      { key: 'hexColor', value: groupColor },
      embedOrigin,
    )
  }

  // Send `postMessage` event to child `<iframe>` to navigate to the listings page
  const navigate = (path: string) => {
    const iframe = document.getElementById(
      'wagerwire-iframe',
    ) as HTMLIFrameElement
    if (!iframe || iframe?.tagName !== 'IFRAME') return

    iframe?.contentWindow?.postMessage(
      { key: 'navigate', value: path },
      embedOrigin,
    )
  }

  const handleClick = async () => {
    if (!groupId || !groupColor)
      console.error(
        '`groupId` and `groupColor` are required to set group information',
      )

    // Send group information to child `<iframe>`
    if (groupId && groupColor) sendGroupInformation()

    // Construct the child `<iframe>`'s `src`
    const src = new URL(iframeSrc)
    const url = new Promise<string>((resolve, reject) => {
      if (!src) reject('src is required')
      resolve(`${src.origin}/listings`)
    })

    // Set the child `<iframe>`'s `src` and make it visible
    navigate(await url)
    setIframeVisible(true)
  }

  return (
    <button
      onClick={handleClick}
      style={{
        border: 'none',
        cursor: 'pointer',
        ...style,
      }}
    >
      {children}
    </button>
  )
}
