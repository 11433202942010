import React, { useEffect } from 'react'

type WagerWireContextProps = {
  /** Origin of the embedded iframe application */
  embedOrigin: string
  /** `src`/URL of the embedded iframe application, see `embed` repo routes */
  iframeSrc: string
  /** Function to update the embedded iframe `src`, displays routes in `embed` repo */
  setIframeSrc: React.Dispatch<React.SetStateAction<string>>
  /** Boolean to determine if embedded iframe is visible or hidden */
  iframeVisible: boolean
  /** Function to update the embedded iframe visibility */
  setIframeVisible: React.Dispatch<React.SetStateAction<boolean>>
  /** Sparket user id */
  userId: string
}

/** React context for WagerWire iframe */
export const WagerWireContext = React.createContext<WagerWireContextProps | null>(
  null,
)

type PostMessageEventKeys = 'close' | 'refresh' | 'portfolio'

type WagerWireProviderProps = {
  /** React children */
  children: React.ReactNode
  /** Origin of the WagerWire embed, needed for `postMessage` security */
  embedOrigin: string
  /** Sparket user id */
  userId: string
}

/**
 * React provider for WagerWire iframe
 *
 * @param children React children
 * @param embedOrigin Origin of the embedded WagerWire application
 */
export const WagerWireProvider = ({
  children,
  embedOrigin,
  userId,
}: WagerWireProviderProps) => {
  const [iframeSrc, setIframeSrc] = React.useState(`${embedOrigin}/listings`)
  const [iframeVisible, setIframeVisible] = React.useState(false)

  // Close `<iframe>` via `postMessage` event sent from `<iframe>`
  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const key = event.data as PostMessageEventKeys
      // console.log('✋ postMessage event key', key)

      if (event.origin !== embedOrigin) return

      if (key === 'close') setIframeVisible(false)

      if (key === 'refresh') window.location.reload()

      if (key === 'portfolio') {
        const origin = window.location.origin
        window.location.assign(`${origin}/mybets`)
      }
    }
    window.addEventListener('message', listener)
    return () => window.removeEventListener('message', listener)
  }, [embedOrigin])

  return (
    <WagerWireContext.Provider
      value={{
        embedOrigin,
        iframeSrc,
        setIframeSrc,
        iframeVisible,
        setIframeVisible,
        userId,
      }}
    >
      {children}
    </WagerWireContext.Provider>
  )
}
